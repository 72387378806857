<template>
  <base-loading v-if="isLoading" />
  <div class="container-fluid mt-3" v-else>
    <base-breadcrumb :pageName="currentPageName" :storeName="storeName" />
    <div class="row">
      <div class="col-8">
        <p class="mb-2 py-3 mb-3">
          Modifier Les Paramètres Par Défaut
        </p>
        <vee-form class="shadow p-4 mt-4  " @submit="onSubmit">
          <div class="form-group row">
            <label class="col-sm-6 col-form-label font-weight-bolder">
              Banque par défaut</label
            >
            <div class="col-sm-6">
              <vee-select
                class="bg-light"
                v-model="defaultBankId"
                :options="banks"
                label="name"
                :reduce="(o) => o.id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label font-weight-bolder"
              >Méthode de paiement par défaut</label
            >
            <div class="col-sm-6">
              <vee-select
                class="bg-light"
                v-model="defaultPaymentMethodId"
                :options="paymentMethods"
                label="title"
                :reduce="(o) => o.id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label font-weight-bolder"
              >Client par défaut</label
            >
            <div class="col-sm-6">
              <vee-select
                class="bg-light"
                v-model="defaultcustomerId"
                :options="customers"
                label="name"
                :reduce="(o) => o.id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label font-weight-bolder"
            >Afficher la TVA dans les Reçus </label>
            <div class="col-sm-6">
              <Toggle v-model="showTaxInReceipt"></Toggle>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label"></label>
            <div class="col-sm-6">
              <button
                :disabled="settings_submission"
                class="btn btn-primary"
              >
                <i class="fas fa-save"></i> Mise à jour
              </button>
            </div>
          </div>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SettingsService from "@/services/settings.service";
import Toggle from "@vueform/toggle";
import "@vueform/toggle/themes/default.css";

const settingsService = new SettingsService();

export default {
  components: {
    Toggle,
  },
  data() {
    return {
      isLoading: false,
      banks: [],
      paymentMethods: [],
      customers: [],
      store: null,
      settings_submission: false,
      amounts: [],
      currency: null,
      currentPageName: "Réglages",
      storeName: null,
      defaultcustomerId: null,
      defaultBankId: null,
      defaultPaymentMethodId: null,
      showTaxInReceipt: true,
    };
  },
  created() {
    this.isLoading = true;
    let { settings, customers } = this.getApiData;
    this.showTaxInReceipt = JSON.parse(localStorage.getItem('showTax')) ?? true;
    if (Object.keys(settings).length) {
      this.store = settings.store;
      this.currency = settings.organization.currency;
      this.storeName = settings.store.title;

      this.defaultBankId = settings.store.bank_id;
      this.defaultPaymentMethodId = settings.store.payment_method_id;
      this.defaultcustomerId = settings.store.account_id;

      this.banks = this.store.banks || [];
      this.paymentMethods = settings.store.payment_methods || [];
      this.customers = customers || [];
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  },
  computed: {
    ...mapGetters(["getApiData"]),
    ...mapActions(["fetchDataFromApi"]),
    formValues() {
      return {
        bank_id: this.store.bank_id,
        payment_method_id: this.store.payment_method_id,
      };
    },
  },
  methods: {
    async onSubmit(data) {
      data.account_id = this.defaultcustomerId || this.store.account_id;
      data.bank_id = this.defaultBankId || this.store.bank_id;
      data.payment_method_id = this.defaultPaymentMethodId || this.store.payment_method_id;
      this.settings_submission = true;
      localStorage.setItem('showTax', this.showTaxInReceipt);

      try {
        await settingsService.update(this.store.id, data);
        this.fetchDataFromApi.then(() => {
          this.$toast.success("La Modification est enregistrée", {
            position: "top-right",
            timeout: 5000,
          });
          this.settings_submission = false;
        });
      } catch (error) {
        this.$toast.error("Modification échouée", {
          position: "top-right",
          timeout: 5000,
        });
        this.settings_submission = false;
      }
    },
  },
  watch: {
    defaultcustomerId(val) {
      if (!val) {
        setTimeout(() => {
          this.defaultcustomerId = this.store.account_id;
        }, 500);
      }
    },
    defaultBankId(val) {
      if (!val) {
        setTimeout(() => {
          this.defaultBankId = this.store.bank_id;
        }, 500);
      }
    },
    defaultPaymentMethodId(val) {
      if (!val) {
        setTimeout(() => {
          this.defaultPaymentMethodId = this.store.payment_method_id;
        }, 500);
      }
    },
  },
};
</script>
